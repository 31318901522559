<template>
  <div class="t_signature">
    <div class="stu-module-header">
      <div class="stu-module-title">电子签名</div>
      <div>
        <span class="upload_message"
          >请上传尺寸大小为120px*60px的电子签名图片</span
        >
        <el-button type="primary" @click="uploadSignature"
          >上传电子签名</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-image :src="imgUrl">
        <div slot="error" class="image-slot">
          <img class="image-none" src="../../../../assets/logo_top.png" />
          <!-- <i class="el-icon-picture-outline"></i> -->
        </div>
      </el-image>
    </div>

    <el-dialog :visible.sync="dialog" width="30%" title="上传电子签名">
      <el-upload
        class="t_signature_upload"
        action="#"
        :show-file-list="false"
        :http-request="uploadFiles"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "t_signature",
  data() {
    return {
      imageUrl: null, // 上传签名
      dialog: false,
      imgUrl: "",
      // "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    uploadSignature() {
      this.dialog = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error("上传头像图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
    },
    uploadFiles(file){
      this.openLoadingView();
      let fileDate = file.file;
      let formData = new FormData();
      formData.append("file",fileDate);
      this.$axios_supermallData.post("/hnjxjy-core/eduProposal/addEduTeacherElec",formData).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("上传成功");
          this.imgUrl = resp.data.data;
          this.loadingView.close();
        }else {
          this.$message.success("上传失败");
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
      this.dialog = false;
    },
    init() {
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduTeacher/getOne?id=" + this.user.id)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.imgUrl = resp.data.data.elecSign;
              this.loadingView.close();
            }else {
              this.loadingView.close();
            }
          }).catch((e)=>{
            this.$message.error(e);
            this.loadingView.close();
          })
    },
  },
};
</script>
<style lang="less">
.t_signature {
  .t_signature_upload {
    text-align: center;
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
<style scoped lang="less">
.t_signature {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
    .upload_message {
      color: #0000ff;
      display: inline-block;
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .content {
    padding: 20px;
    text-align: center;
    .el-image {
      background: #f2f2f2;
    }
    .image-none {
      border-radius: 4px;
      width: 200px;
      height: 130px;
      margin: 6px auto;
      display: block;
    }
  }
}
</style>
